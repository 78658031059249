@tailwind base;
@tailwind components;
@tailwind utilities;


.main-bg {
    background-image: url('assets/images/main.webp'); /* Путь к вашему изображению */
    background-size: cover; /* Изображение покрывает весь элемент */
    background-position: right; /* Центрирование изображения */
    background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .main-bg {
        display: block; /* Показать мобильный фон */
        background-image: url('assets/images/mobBg.webp'); /* Путь к вашему изображению */
        background-size: cover; /* Изображение покрывает весь элемент */
        background-position: right; /* Центрирование изображения */
        background-repeat: no-repeat;
    }

    .main-bg-mob {
        display: block; /* Показать мобильный фон */
        background-image: url('assets/images/mobBg.webp'); /* Путь к вашему изображению */
        background-size: cover; /* Изображение покрывает весь элемент */
        background-position: right; /* Центрирование изображения */
        background-repeat: no-repeat;
    }
}

@media (min-width: 769px) {
    .main-bg-mob {
        display: block; /* Показать мобильный фон */
        background-image: url('assets/images/mobBg.webp'); /* Путь к вашему изображению */
        background-size: cover; /* Изображение покрывает весь элемент */
        background-position: right; /* Центрирование изображения */
        background-repeat: no-repeat;
    }
}
.grad-bg {
    background-image: url('assets/images/grad.webp'); /* Путь к вашему изображению */
    background-size: cover; /* Изображение покрывает весь элемент */
    background-position: right; /* Центрирование изображения */
    background-repeat: no-repeat;
}
.grad-bg-species {
    background-image: url('assets/images/gradSpe.webp'); /* Путь к вашему изображению */
    background-size: cover; /* Изображение покрывает весь элемент */
    background-position: right; /* Центрирование изображения */
    background-repeat: no-repeat;
}
.country-bg {
    background-image: url('assets/images/country.webp'); /* Путь к вашему изображению */
    background-size: cover; /* Изображение покрывает весь элемент */
    background-position: right; /* Центрирование изображения */
    background-repeat: no-repeat;
}
.shadow{
    box-shadow: 20px 22px 76px 0px rgba(0, 0, 0, 0.2);

}
.flip-card {
    background-color: transparent;
    width: 440px;
    height: 350px;
    perspective: 1000px; /* Устанавливаем перспективу */
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s ease; /* Плавный переход */
    transform-style: preserve-3d; /* Сохраняем 3D-эффект */
}

.flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg); /* Поворачиваем на 180 градусов */
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Скрываем заднюю сторону при повороте */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.flip-card-front {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.flip-card-back {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    transform: rotateY(180deg); /* Задняя сторона изначально повёрнута */
}
.card {
    position: relative;
}

.front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 1rem; /* Для округлых углов */
    transition: transform 0.9s ease; /* Плавный переход */
    transform-style: preserve-3d; /* Сохраняем 3D-эффект */

}

.front {
    background-color: white; /* Цвет передней стороны */


}

.back {
    background-color: lightgray; /* Цвет задней стороны */
    transform: rotateY(180deg);


}

.card.flipped .front {
    transform: rotateY(180deg);

}

.card.flipped .back {
    transform: rotateY(0);


}
@layer components {
    .bg-gradient-hover {
       background: #11141D;
    }

    .bg-gradient-hover:hover {
        background: linear-gradient(89.9deg, #11141D 0.09%, #4A62FF 138.99%);
        transition: background 0.3s ease;
    }
}
.headBg{
background: rgba(255, 255, 255, 0.15)
}